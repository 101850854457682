import { Heading, Text } from '../typography'
import * as styles from './dupes.module.css'
import * as sharedStyles from '../../../styles.module.css'
import classNames from 'classnames'
import {
  type Contact,
  ContactStatus,
  type UpdateContactInput,
  type AddContactInput,
  type AmclDuplicateContact,
  type BullhornDuplicateContact,
  type Company
} from '~/src/__generated__/gql-types'
import { Icon } from '../icons/Icons'
import { formatStatus } from './formatStatus'

export type ContactDupeCardType = AddContactInput &
  AmclDuplicateContact &
  BullhornDuplicateContact &
  Contact &
  UpdateContactInput
export const ContactDupeCard = ({
  contact,
  type,
  company,
  width = 'default'
}: {
  contact: ContactDupeCardType
  type: 'input' | 'partial' | 'bullhorn'
  company?: Company
  width?: 'default' | 'narrow'
}): JSX.Element => {
  const bullhornContactUrl = (id: string): string => {
    return `https://cls42.bullhornstaffing.com/BullhornSTAFFING/OpenWindow.cfm?Entity=ClientContact&id=${id}`
  }
  const bullhornLogo = new URL('../assets/bullhorn_logo.png', import.meta.url)

  const hasPhoneDirect: boolean = !!contact.phone_direct
  const phone = contact.phone === '' ? undefined : contact.phone

  return (
    <div
      className={classNames([styles.dupe, styles['dupe-card'], styles[width]])}
    >
      <div
        className={classNames(sharedStyles.flex, sharedStyles['space-between'])}
      >
        <Heading level={width === 'narrow' ? 'five' : 'four'} as='h3'>
          {type !== 'input' ? (
            <>
              {contact.owner.first_name} {contact.owner.last_name}{' '}
              <Text style='five' as='span' weight='regular'>
                ({contact.owner.division ?? 'Unknown'})
              </Text>
            </>
          ) : (
            'Your Contact:'
          )}
        </Heading>
        {type === 'bullhorn' && contact.id && (
          <Text
            style='link'
            as='a'
            linkNewTab={true}
            href={bullhornContactUrl(contact.id)}
          >
            <img
              src={bullhornLogo.toString()}
              className={styles['bullhorn-logo']}
              alt='Bullhorn'
            />
          </Text>
        )}
      </div>
      <div
        className={
          width === 'narrow' ? styles['small-container'] : styles.container
        }
      >
        <div className={width === 'default' && sharedStyles.flex}>
          <div className={width === 'default' && styles.column}>
            <div className={styles.details}>
              <Text style='detail' as='p'>
                Full Name:
              </Text>
              <Text style='detail' as='p' weight='bold'>
                {contact.first_name} {contact.last_name}
              </Text>
            </div>
          </div>
          <div className={width === 'default' && styles.column}>
            <div className={styles.details}>
              <Text style='detail' as='p'>
                Company:
              </Text>
              <Text style='detail' as='p' weight='bold'>
                {contact.company_name ?? company?.name ?? 'Not Entered'}
                {}
              </Text>
            </div>
          </div>
          <div className={width === 'default' && styles.column}>
            <div className={styles.details}>
              <Text style='detail' as='p'>
                Title:
              </Text>
              <Text
                style='detail'
                as='p'
                weight='bold'
                color={contact.title ? 'black' : 'disabled-text'}
              >
                {contact.title ? contact.title : 'Not Entered'}
              </Text>
            </div>
          </div>
        </div>
        <div className={width === 'default' && sharedStyles.flex}>
          <div className={width === 'default' && styles.column}>
            <div className={styles.details}>
              <Text style='detail' as='p'>
                Phone:
              </Text>
              <Text
                style='detail'
                as='p'
                weight='bold'
                color={
                  phone ?? contact.phone_direct ?? contact.phone_direct
                    ? 'black'
                    : 'disabled-text'
                }
              >
                {phone ?? hasPhoneDirect ? contact.phone_direct : 'Not Entered'}
              </Text>
            </div>
          </div>
          <div className={width === 'default' && styles.column}>
            <div className={styles.details}>
              <Text style='detail' as='p'>
                Email:
              </Text>
              <Text
                style='detail'
                as='p'
                weight='bold'
                color={contact.email ? 'black' : 'disabled-text'}
              >
                {contact.email ? contact.email : 'Not Entered'}
              </Text>
            </div>
          </div>
        </div>
        <div className={width === 'default' && sharedStyles.flex}>
          {type !== 'bullhorn' && (
            <div className={width === 'default' && styles.column}>
              <div className={styles.details}>
                <Text style='detail' as='p'>
                  Notes:
                </Text>
                <Text
                  style='detail'
                  as='p'
                  weight='bold'
                  color={contact.notes ? 'black' : 'disabled-text'}
                >
                  {contact.notes ? contact.notes : 'Not Entered'}
                </Text>
              </div>
            </div>
          )}
          {type === 'partial' && (
            <div className={width === 'default' && styles.column}>
              <div className={styles.details}>
                <Text style='detail' as='p'>
                  Status:
                </Text>
                <Text style='detail' as='p' weight='bold'>
                  <span
                    className={classNames(
                      sharedStyles.flex,
                      sharedStyles['align-center']
                    )}
                  >
                    {formatStatus(contact.status)}
                    <Icon
                      symbol='CircleIcon'
                      color={
                        contact.status === ContactStatus.Dba ||
                        contact.status === ContactStatus.Requalified
                          ? 'text-green'
                          : contact.status === ContactStatus.Disqualified
                          ? 'alku-red'
                          : 'bright-blue'
                      }
                      size='xs'
                      space='left'
                    />
                  </span>
                </Text>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

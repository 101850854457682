import {
  Form,
  Formik,
  type FormikProps,
  type FormikHelpers,
  ErrorMessage
} from 'formik'
import { useEffect, useMemo, useRef, useState, type FC } from 'react'
import classNames from 'classnames'
import { Button } from '@alku/ui-kit'
import {
  type DbaContactInput,
  type Contact,
  InternOrigin,
  ContactSource,
  useGetContactCategoriesQuery,
  useDbaContactMutation,
  PhoneType,
  type Company,
  type ListType,
  type DbaContactMutation,
  GetActiveListSummaryDocument,
  GetLeadsListSummaryDocument,
  useFindDuplicateContactsLazyQuery,
  DuplicateType,
  useGetDistributionListOptionsLazyQuery,
  type DbaActionType,
  type DbaTradeshow,
  GetActiveListDocument,
  useGetExplorersMarketsQuery
} from '../../../../__generated__/gql-types'
import { TextInput } from '../../../shared/forms/form-elements'
import hasError from '../../../shared/forms/helpers/hasError'
import * as styles from './AddDBAForm.module.css'
import useGetCompanyAndDivisionByRef from './hooks/useGetCompanyAndDivisionByRef'
import {
  SelectDropdown,
  SelectDropdownField
} from '../../../shared/select/SelectDropdown'
import { type TogglePopover } from '../../../table/Table'
import {
  DBAFormSchema,
  EXPLORER_MARKETS_DIVISION,
  getSelectedOptions,
  internOriginOptions,
  phoneTypeOptions
} from './constants/formData'
import { type SelectValue } from '../../../shared/select/types'
import { type BaseMutationOptions, type StoreObject } from '@apollo/client'
import { useAuth } from '~/src/auth/hooks/useAuth'
import { ContactDuplicatesComponent } from '../../ContactsDupesModal/ContactDuplicatesComponent'
import {
  sourceOptions,
  groupedActionOptions,
  tradeshowOptions
} from './constants/helper'
import { MultiSelectDropdown } from '~/src/components/shared/select/MultiSelectDropdown'
import { type GroupBase } from 'react-select'
import { ValidationError } from '~/src/components/shared/validationError/ValidationError'

interface Props {
  contact: Contact
  onRequestClose: () => void
  togglePopover: TogglePopover
  listType: ListType
  companyObject: Company
  isModalOpen: boolean
}

export const AddDBAForm: FC<Props> = (props) => {
  const {
    contact,
    onRequestClose,
    togglePopover,
    listType,
    companyObject,
    isModalOpen
  } = props

  const [selectedPhoneType, setSelectedPhoneType] = useState<PhoneType | null>(
    contact.preferred_phone ?? PhoneType.Direct
  )

  // #region gql
  const [dbaContact, { loading }] = useDbaContactMutation({
    update: (cache, result) => {
      const rootKey = 'dbaContact' as keyof DbaContactMutation

      if (!result?.data?.[rootKey]) return

      // get the contact object from the cache
      const contactCacheKey = cache.identify(
        result.data[rootKey] as StoreObject
      )

      // if the contact object is not in the cache, return
      if (!contactCacheKey) return

      // this indicates that the contact was moved to another list during the mutation
      if (result.data[rootKey].contact?.ref_company !== companyObject.id) {
        cache.modify({
          id: cache.identify(companyObject),
          fields: {
            contacts(existingContactRefs) {
              return [
                ...existingContactRefs.filter(
                  (element: { __ref: string }) =>
                    element.__ref !== contactCacheKey
                )
              ]
            }
          }
        })
      }
    }
  })

  const { data: categoriesData } = useGetContactCategoriesQuery()
  const { company, division } = useGetCompanyAndDivisionByRef(
    listType,
    contact?.ref_company as string
  )
  // #endregion gql

  const [isCheckingDupes, setIsCheckingDupes] = useState<boolean>(false)
  const [showDuplicates, setShowDuplicates] = useState<boolean>(false)
  const [hasSubmitted, setHasSubmitted] = useState(false)
  const handleCloseModal = (): void => {
    setShowDuplicates(false)
    setIsCheckingDupes(false)
    setHasSubmitted(false)
    setSubmitting(false)
    if (isModalOpen) onRequestClose()
  }

  const formRef = useRef<FormikProps<typeof initialValues>>(null)

  const [
    findDuplicates,
    { data: duplicates, loading: duplicatesLoading, error: duplicatesError }
  ] = useFindDuplicateContactsLazyQuery()

  const { data: explorersMarkets, loading: loadingExplorersMarkets } =
    useGetExplorersMarketsQuery()

  const [explorersMarketsOptions, setExplorersMarketsOption] =
    useState<Array<SelectValue<number>>>()

  useEffect(() => {
    if (
      !explorersMarketsOptions &&
      !loadingExplorersMarkets &&
      explorersMarkets
    ) {
      const options = explorersMarkets.getExplorersMarkets.map((market) => ({
        label: market.name,
        value: market.bullhorn_id
      }))

      setExplorersMarketsOption(options)
    }
  }, [explorersMarkets, explorersMarketsOptions, loadingExplorersMarkets])

  const [
    getDistributionLists,
    { data: distributionList, loading: distributionListLoading }
  ] = useGetDistributionListOptionsLazyQuery()

  const distributionListOptions = useMemo(() => {
    if (!distributionList?.getDistributionListOptions) return []

    const options = distributionList.getDistributionListOptions.map((list) => ({
      label: `${list.display_name} - (${list.email})`,
      value: list.email
    }))

    return options
  }, [distributionList])

  useEffect(() => {
    void getDistributionLists()
  }, [getDistributionLists])

  const [submitting, setSubmitting] = useState(false)

  const bullhornMatches = useMemo(() => {
    return duplicates?.findDuplicateContacts.bullhorn
  }, [duplicates])

  const amclPartialMatches = useMemo(() => {
    return duplicates?.findDuplicateContacts.amcl.filter(
      (data) =>
        data.type === DuplicateType.AmclSoftContact && data.id !== contact?.id
    )
  }, [contact?.id, duplicates])

  const amclHardMatches = useMemo(() => {
    return duplicates?.findDuplicateContacts.amcl.filter(
      (data) =>
        data.type === DuplicateType.AmclHardContact && data.id !== contact?.id
    )
  }, [contact?.id, duplicates])

  useEffect(() => {
    if (
      amclHardMatches !== undefined &&
      bullhornMatches !== undefined &&
      amclPartialMatches !== undefined
    ) {
      if (
        amclHardMatches.length > 0 ||
        bullhornMatches.length > 0 ||
        amclPartialMatches.length > 0
      ) {
        // if dupes found show them
        setShowDuplicates(true)
        setIsCheckingDupes(false)
      } else if (
        amclHardMatches.length === 0 &&
        bullhornMatches.length === 0 &&
        amclPartialMatches.length === 0 &&
        !hasSubmitted
      ) {
        // proceed with mutation
        if (formRef.current && !hasSubmitted) {
          setSubmitting(true)
          setIsCheckingDupes(false)
          formRef.current.handleSubmit()
          setHasSubmitted(true)
        }
      }
    }
  }, [
    isCheckingDupes,
    amclHardMatches,
    bullhornMatches,
    amclPartialMatches,
    hasSubmitted
  ])

  const handleDupeCheck = async (
    values: typeof initialValues
  ): Promise<void> => {
    if (values) {
      await findDuplicates({
        variables: {
          company_id: companyObject.id,
          first_name: values.first_name,
          last_name: values.last_name,
          email: values.email
        },
        fetchPolicy: 'no-cache' // Avoid cached dupes gql queries
      })
    }
  }

  const { id } = useAuth()

  const handleSubmit = async (
    values: typeof initialValues,
    actions: FormikHelpers<typeof initialValues>
  ): Promise<void> => {
    actions.setSubmitting(false)

    if (!isCheckingDupes) {
      const mutationOptions: Pick<
        BaseMutationOptions,
        'onCompleted' | 'onError'
      > = {
        onCompleted: (data) => {
          const { contact, error: dbaError } = data.dbaContact
          if (dbaError) {
            if (dbaError.__typename === 'DbaNoteError') {
              handleDbaPopover(
                true,
                'DBA contact successful, but failed to create note. See Bullhorn.'
              )
            } else if (dbaError.__typename === 'DbaTaskError') {
              handleDbaPopover(
                true,
                'DBA contact successful, but failed to create task. See Bullhorn.'
              )
            } else if (dbaError.__typename === 'DbaEmailError') {
              handleDbaPopover(
                true,
                'DBA contact successful, but failed to send email. See Bullhorn.'
              )
            }
          }
          if (contact && !dbaError) {
            window.dataLayer.push({
              event: 'ga-dba-contact'
            })
            togglePopover(
              'success',
              `DBA'd ${contact.first_name as string} ${
                contact.last_name as string
              }`,
              3000
            )
          }
          if (!contact) togglePopover('error', 'Failed to DBA contact', 3000)

          handleCloseModal()
        },
        onError(error) {
          handleCloseModal()
          togglePopover(
            'error',
            `Failed to DBA ${contact.first_name} ${contact.last_name} - ${error.message}`,
            3000
          )
        }
      }

      delete values.company_name

      try {
        await dbaContact({
          ...mutationOptions,
          variables: { id: contact.id, input: values },
          refetchQueries: [
            {
              query: GetActiveListSummaryDocument,
              variables: { userId: id }
            },
            {
              query: GetLeadsListSummaryDocument,
              variables: { userId: id }
            },
            {
              query: GetActiveListDocument,
              variables: { userId: id }
            }
          ],
          update: (cache, result) => {
            const rootKey = 'dbaContact'

            if (!result?.data?.[rootKey]) return

            const { contact } = result.data[rootKey]

            if (contact) {
              // Get the contact object from the cache
              const contactCacheKey = cache.identify(contact)

              // If the contact object is not in the cache, return
              if (!contactCacheKey) return

              // This indicates that the contact was moved to another list during the mutation
              if (contact.ref_company !== companyObject.id) {
                cache.modify({
                  id: cache.identify(companyObject),
                  fields: {
                    contacts(existingContactRefs) {
                      return existingContactRefs.filter(
                        (element: { __ref: string }) =>
                          element.__ref !== contactCacheKey
                      )
                    }
                  }
                })
              }
            }
          }
        })
      } catch (error) {
        console.error('An unexpected error occurred:', error)
        togglePopover(
          'error',
          `An unexpected error occurred while trying to DBA ${contact.first_name} ${contact.last_name}`,
          3000
        )
      }
    }
  }

  // Function to handle different DBA errors
  const handleDbaPopover = (success: boolean, error: string): void => {
    togglePopover(success ? 'success' : 'error', error, 3000)
  }

  // #region computed data
  const divisionOptions: Array<SelectValue<number>> = categoriesData
    ? categoriesData.contactCategories.map((category) => ({
        value: category?.bullhorn_id as number,
        label: category?.name as string
      }))
    : []
  const initialValues: DbaContactInput & { company_name?: string } = {
    first_name: contact.first_name,
    last_name: contact.last_name,
    company_name: company?.name,
    company_bullhorn_id: company?.bullhorn_id as number,
    email: contact?.email?.trim() ?? '',
    phone_direct: contact?.phone_direct ?? '',
    phone_mobile: contact?.phone_mobile ?? '',
    phone_other: contact?.phone_other ?? '',
    title: contact?.title ?? '',
    division_bullhorn_id:
      Number(divisionOptions.find((opt) => opt.label === division)?.value) ??
      undefined,
    explorers_markets_bullhorn_id: undefined,
    intern_origin: InternOrigin.No,
    SUPP: '',
    source: ContactSource.YellowLead,
    tradeshow: undefined,
    action: undefined,
    notes: '',
    email_subscriptions: []
  }
  const phoneKey: 'phone_direct' | 'phone_other' | 'phone_mobile' = `phone_${
    selectedPhoneType?.toLowerCase() as 'direct' | 'other' | 'mobile'
  }`
  // #endregion computed data

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={DBAFormSchema}
      onSubmit={handleSubmit}
      innerRef={formRef}
    >
      {({
        errors,
        handleChange,
        values,
        touched,
        setFieldValue,
        setFieldTouched
      }) => (
        <>
          {showDuplicates && (
            <>
              <br />
              <ContactDuplicatesComponent
                amclHardMatches={amclHardMatches ?? []}
                amclPartialMatches={amclPartialMatches ?? []}
                bullhornMatches={bullhornMatches ?? []}
                contactObject={values}
                companyObject={companyObject}
                loading={duplicatesLoading}
                error={duplicatesError}
                type='add'
              />
            </>
          )}
          <Form id='dba-contact' className={styles.form}>
            {!showDuplicates && (
              <>
                <div className={styles['form-element']}>
                  <TextInput
                    label='First Name'
                    id='first_name'
                    name='first_name'
                    error={errors.first_name}
                    value={values.first_name}
                    readOnly
                    required
                  />
                </div>
                <div className={styles['form-element']}>
                  <TextInput
                    label='Last Name'
                    id='last_name'
                    name='last_name'
                    error={errors.last_name}
                    value={values.last_name}
                    readOnly
                    required
                  />
                </div>
                <div className={styles['form-element']}>
                  <TextInput
                    label='Company'
                    id='company_name'
                    name='company_name'
                    error={errors.company_bullhorn_id}
                    value={company?.name}
                    readOnly
                    required
                  />
                </div>
                <div className={styles['form-element']}>
                  <TextInput
                    label='Email'
                    id='email'
                    name='email'
                    error={errors.email}
                    value={values.email}
                    readOnly={true}
                    required
                  />
                </div>
                <div className={styles.group}>
                  <div
                    className={classNames(
                      styles['form-element'],
                      styles['phone-type']
                    )}
                  >
                    <SelectDropdown<PhoneType>
                      ariaLabel='Phone Numbers'
                      name='phone'
                      label='Phone'
                      required
                      value={phoneTypeOptions[selectedPhoneType as PhoneType]}
                      defaultOptionSelected
                      options={Object.values(phoneTypeOptions)}
                      className={styles['phone-select']}
                      onChange={(value) => {
                        setSelectedPhoneType(value)
                      }}
                    />
                  </div>
                  <div
                    className={classNames(
                      styles['form-element'],
                      styles['phone-number']
                    )}
                  >
                    <TextInput
                      key={phoneKey}
                      id={phoneKey}
                      name={phoneKey}
                      noLabel
                      error={errors[phoneKey]}
                      value={values[phoneKey]}
                      readOnly
                      required
                    />
                  </div>
                </div>
                <div className={styles['form-element']}>
                  <TextInput
                    label='Title'
                    id='title'
                    name='title'
                    error={errors.title}
                    value={values.title}
                    readOnly
                    required
                  />
                </div>
                <div className={styles['form-element']}>
                  <SelectDropdownField<number>
                    id='division_bullhorn_id'
                    name='division_bullhorn_id'
                    label='Division'
                    defaultOptionSelected
                    options={divisionOptions}
                    value={divisionOptions.find(
                      (opt) =>
                        opt.value === (values?.division_bullhorn_id ?? '')
                    )}
                    hasError={hasError(errors.division_bullhorn_id)}
                    required
                    touched={touched.division_bullhorn_id}
                  />
                </div>
                {values.division_bullhorn_id === EXPLORER_MARKETS_DIVISION && (
                  <div className={styles['form-element']}>
                    <SelectDropdownField<number>
                      id='explorers_markets_bullhorn_id'
                      name='explorers_markets_bullhorn_id'
                      label='Explorers Markets'
                      defaultOptionSelected
                      options={explorersMarketsOptions ?? []}
                      value={explorersMarketsOptions?.find(
                        (opt) =>
                          opt.value ===
                          (values?.explorers_markets_bullhorn_id ?? '')
                      )}
                      hasError={hasError(errors.explorers_markets_bullhorn_id)}
                      required
                      touched={touched.explorers_markets_bullhorn_id}
                    />
                  </div>
                )}
                <div className={styles['form-element']}>
                  <SelectDropdownField<InternOrigin>
                    id='intern_origin'
                    name='intern_origin'
                    label='Intern Origin'
                    options={Object.values(internOriginOptions)}
                    defaultOptionSelected
                    value={internOriginOptions[values.intern_origin]}
                    hasError={hasError(errors.intern_origin)}
                    required
                    touched={touched.intern_origin}
                  />
                </div>
                <div className={styles['form-element']}>
                  <TextInput
                    label='SUPP'
                    id='SUPP'
                    name='SUPP'
                    error={errors.SUPP}
                    handleChange={handleChange}
                    value={values.SUPP}
                    required
                    touched={touched.SUPP}
                  />
                </div>
                <div className={styles['form-element']}>
                  <SelectDropdownField<ContactSource>
                    id='source'
                    name='source'
                    label='Source'
                    defaultOptionSelected
                    options={Object.values(sourceOptions)}
                    value={sourceOptions[values.source]}
                    hasError={hasError(errors.source)}
                    required
                    touched={touched.source}
                  />
                </div>

                {values.source === ContactSource.Tradeshow && (
                  <div className={styles['form-element']}>
                    <SelectDropdownField<DbaTradeshow>
                      id='tradeshow'
                      name='tradeshow'
                      label='Tradeshow Attended'
                      defaultOptionSelected
                      options={Object.values(tradeshowOptions)}
                      value={
                        values.tradeshow
                          ? tradeshowOptions[values.tradeshow]
                          : undefined
                      }
                      hasError={hasError(errors.tradeshow)}
                      required
                      touched={touched.tradeshow}
                    />
                  </div>
                )}
                <div className={styles['form-element']}>
                  <TextInput
                    id='notes'
                    name='notes'
                    value={values?.notes ?? ''}
                    error={errors.notes}
                    hasError={hasError(errors.notes)}
                    handleChange={handleChange}
                    label='Add Note'
                    required
                    touched={touched.notes}
                    multiline
                  />
                </div>
                <div className={styles['form-element']}>
                  <MultiSelectDropdown
                    options={distributionListOptions}
                    isSearchable
                    placeholder='Start typing to select collegues'
                    name='email_subscriptions'
                    label='Internal Distribution List'
                    required
                    id='email_subscriptions'
                    isLoading={distributionListLoading}
                    value={getSelectedOptions(
                      distributionListOptions,
                      values.email_subscriptions as string[]
                    )}
                    hasError={hasError(errors.email_subscriptions)}
                    touched={touched.email_subscriptions}
                    onBlur={() => {
                      void setFieldTouched('email_subscriptions')
                    }}
                    onChange={(value) => {
                      void setFieldValue('email_subscriptions', value)
                    }}
                  />
                  <ErrorMessage
                    name='email_subscriptions'
                    component={ValidationError}
                    className='error'
                  />
                </div>
                <div className={styles['form-element']}>
                  <SelectDropdownField<GroupBase<DbaActionType>>
                    id='action'
                    name='action'
                    label='Task Action'
                    defaultOptionSelected
                    options={Object.values(groupedActionOptions)}
                    value={groupedActionOptions[values.action]}
                    hasError={hasError(errors.action)}
                    required
                    touched={touched.action}
                  />
                </div>
              </>
            )}
            {!duplicatesLoading && !showDuplicates ? (
              <Button
                id='submit-dba'
                as='button'
                type='submit'
                style='primary-impact'
                ariaLabel='Submit'
                disabled={duplicatesLoading || submitting || loading}
                onClick={async () => {
                  if (!Object.keys(errors).length) {
                    await handleDupeCheck(values)
                    setIsCheckingDupes(true)
                  } else {
                    console.log(errors)
                    setIsCheckingDupes(false)
                  }
                }}
              >
                Submit
              </Button>
            ) : (
              <>
                <Button
                  id='submit-dba'
                  as='button'
                  type='submit'
                  style='primary-impact'
                  ariaLabel='Add DBA'
                  onClick={() => {
                    setIsCheckingDupes(false)
                  }}
                  disabled={duplicatesLoading || submitting || loading}
                >
                  Add DBA Anyway
                </Button>
                {!duplicatesLoading && (
                  <Button
                    as='button'
                    type='button'
                    style='primary-default'
                    onClick={() => {
                      handleCloseModal()
                    }}
                    disabled={duplicatesLoading || submitting || loading}
                    className={styles['cancel-button']}
                  >
                    Don’t Add DBA
                  </Button>
                )}
              </>
            )}
          </Form>
        </>
      )}
    </Formik>
  )
}

import classNames from 'classnames'
import {
  type CallListSummary,
  useGetAllCallListSummariesQuery,
  type GetAllCallListSummariesQuery
} from '../__generated__/gql-types'
import DirectoryFilters from '../components/directory/DirectoryFilters'
import { Container } from '../components/shared/container/Container'
import { Tile } from '../components/shared/tile/Tile'
import { Heading } from '../components/shared/typography'
import * as sharedStyles from '../styles.module.css'
import { useCallback, useState } from 'react'
import { Button } from '../components/shared/button/Button'
import { TileType, type SelectValue } from '../components/shared/select/types'
import { useAuth } from '../auth/hooks/useAuth'
import { Spinner } from '../components/shared/spinner/Spinner'

type Summary = NonNullable<
  NonNullable<GetAllCallListSummariesQuery['allCallListsSummaries']>[number]
> | null

export const Directory = (): JSX.Element => {
  const { data, error, loading } = useGetAllCallListSummariesQuery()
  const [divisionSearch, setDivisionSearch] = useState<Array<
    SelectValue<string>
  > | null>(null)
  const [keywordSearch, setKeywordSearch] = useState<
    SelectValue | null | undefined
  >(null)

  const { id } = useAuth()

  const filterSummaries = useCallback(
    (summary: Summary): boolean => {
      if (summary === null || summary.user_ids.id === id) return false
      let shouldShow = true
      if (
        divisionSearch !== null &&
        divisionSearch.length > 0 &&
        !divisionSearch.some(
          (div) => div?.value === summary.user_division?.toString()
        )
      )
        shouldShow = false
      if (keywordSearch && summary?.id !== keywordSearch?.value)
        shouldShow = false
      return shouldShow
    },
    [divisionSearch, keywordSearch, id]
  )

  const sortSummaries = useCallback((a: Summary, b: Summary): number => {
    return (a?.last_name ?? '').localeCompare(b?.last_name ?? '')
  }, [])

  const clearFilters = (e: React.MouseEvent<HTMLElement>): void => {
    e.preventDefault()
    setDivisionSearch(null)
    setKeywordSearch(null)
  }

  const getSelectedDivisionsText = (
    divisionSearch: Array<SelectValue<string>>
  ): string => {
    let divisionSearchString = ''
    const lastIndex = divisionSearch.length - 1

    divisionSearch.forEach((division, index) => {
      if (index > 0 && index !== lastIndex)
        divisionSearchString += `${', '}"${division.label}"`
      else if (index !== 0 && index === lastIndex)
        divisionSearchString += `${', or '}"${division.label}"`
      else divisionSearchString += `"${division.label}"`
    })

    return divisionSearchString
  }

  if (error != null) console.log('error:', error)

  if (loading) return <Spinner fullScreen />

  return (
    <Container>
      <header
        className={classNames(
          sharedStyles.flex,
          sharedStyles['space-between'],
          sharedStyles['align-center']
        )}
      >
        <Heading as='h1' level='one'>
          Directory
        </Heading>
        {data !== undefined && (
          <DirectoryFilters
            data={data as { allCallListsSummaries?: CallListSummary[] }}
            onNameChange={setKeywordSearch}
            onDivChange={setDivisionSearch}
            selectedName={keywordSearch}
            selectedDivisions={divisionSearch}
          />
        )}
      </header>
      <div className={sharedStyles.section}>
        <div className={sharedStyles.listContainer}>
          {typeof loading === 'boolean' &&
            !loading &&
            data?.allCallListsSummaries
              .filter(filterSummaries)
              .sort(sortSummaries)
              .map(
                (list) =>
                  list != null && (
                    <Tile
                      tileType={TileType.DEFAULT}
                      type={'default'}
                      key={`call-list-tile-${list.id}`}
                      data={list as CallListSummary}
                      id={list.user_ids.id}
                    />
                  )
              )}
        </div>
        {typeof loading === 'boolean' &&
          !loading &&
          data?.allCallListsSummaries.filter(filterSummaries).length === 0 && (
            <div className={classNames(sharedStyles.container)}>
              <Heading as='h2' level='four'>
                There are no results{' '}
                {keywordSearch && (
                  <>
                    for{' '}
                    <>&quot;{keywordSearch ? keywordSearch.label : ''}&quot; </>
                    {divisionSearch && (
                      <>in {getSelectedDivisionsText(divisionSearch)}</>
                    )}
                  </>
                )}
              </Heading>
              <br />
              <Button
                as='button'
                style='link'
                className={sharedStyles['bright-blue']}
                onClick={clearFilters}
              >
                Clear filters
              </Button>
            </div>
          )}
      </div>
    </Container>
  )
}

import { useCallback, useMemo, useState } from 'react'
import { Button, Flyout, FlyoutListItem } from '@alku/ui-kit'
import * as styles from './contact-flyout.module.css'

import { Icon } from '~/src/components/shared/icons/Icons'
import { handleUpdateContact } from '../../helpers/handleUpdateContact'
import { handleMoveContactToActive } from '../../helpers/handleMoveContact'
import { AccordionTypes } from '~/src/components/call-list/company/CompanySection'
import { Modal } from '~/src/components/shared/modal/Modal'
import {
  useUpdateContactMutation,
  ContactStatus,
  useReferContactMutation,
  ListType,
  useMoveContactMutation,
  GetActiveListSummaryDocument
} from '~/src/__generated__/gql-types'

import type {
  Company,
  Contact,
  UpdateContactMutation,
  ReferContactMutation
} from '~/src/__generated__/gql-types'

import { SelectDBAForm } from '~/src/components/call-list/DBA/SelectDBAFormContainer/SelectDBAForm'
import { type TogglePopover } from '../../Table'
import { type StoreObject } from '@apollo/client'
import { ArchiveModal } from '~/src/components/call-list/ArchiveModal/ArchiveModal'
import { MoveContactModal } from '~/src/components/table/components/MoveContactModal/MoveContactModal'
import { useAuth } from '~/src/auth/hooks/useAuth'
import classNames from 'classnames'
import { FlyoutHelpText } from '~/src/components/shared/flyout/FlyoutHelpText'

interface ContactFlyoutProps {
  openEditContactForm?: () => void
  contact: Contact
  togglePopover: TogglePopover
  contactCategory: string
  companyObject: Company
  listType: ListType
  position?: 'top' | 'bottom'
}

export const ContactFlyout = ({
  openEditContactForm,
  togglePopover,
  contactCategory,
  contact,
  companyObject,
  listType = ListType.Active,
  position = 'bottom'
}: ContactFlyoutProps): JSX.Element => {
  const contactHasBullhornId = Boolean(contact.bullhorn_id)
  const companyHasBullhornId = Boolean(companyObject.bullhorn_id)
  const contactHasTitleLongerThan50 = Boolean((contact.title ?? '').length > 50)
  const contactName = `${contact.first_name} ${contact.last_name}`
  const isDBAActionDisabled =
    ![contact.email, contact.phone_direct, contact.title].every(
      (field) => field
    ) || !companyHasBullhornId
  const [contactFlyoutOpen, setContactFlyoutOpen] = useState(false)
  const [isSelectDBAModalOpen, setIsSelectDBAModalOpen] =
    useState<boolean>(false)
  const [isMoveContactModalOpen, setIsMoveContactModalOpen] =
    useState<boolean>(false)
  const toggleContactFlyout = (): void => {
    setContactFlyoutOpen((bool) => !bool)
  }

  const [archiveDialogOpen, setArchiveDialogOpen] = useState(false)

  const [referContactFn] = useReferContactMutation({
    update: (cache, result) => {
      const rootKey = 'referContact' as keyof ReferContactMutation

      if (!result?.data?.[rootKey]) return

      // get the contact object from the cache
      const contactCacheKey = cache.identify(
        result.data[rootKey] as StoreObject
      )

      // if the contact object is not in the cache, return
      if (!contactCacheKey) return

      // update the contact in the cache
      cache.modify({
        id: contactCacheKey,
        fields: {
          referral() {
            return !contact.referral
          }
        }
      })
    }
  })

  const { id } = useAuth()

  const [updateContactFn, { error }] = useUpdateContactMutation({
    update: (cache, result) => {
      const rootKey = 'updateContact' as keyof UpdateContactMutation

      if (!result?.data?.[rootKey]) return

      // get the contact object from the cache
      const contactCacheKey = cache.identify(
        result.data[rootKey] as StoreObject
      )

      // if the contact object is not in the cache, return
      if (!contactCacheKey) return

      // remove the contact from the cache if the status is archived
      if (result.data[rootKey].status === ContactStatus.Archived) {
        cache.modify({
          id: cache.identify(companyObject),
          fields: {
            contacts(existingContactRefs) {
              return [
                ...existingContactRefs.filter(
                  (element: { __ref: string }) =>
                    element.__ref !== contactCacheKey
                )
              ]
            }
          }
        })
      }
    },
    refetchQueries: [
      {
        query: GetActiveListSummaryDocument,
        variables: { userId: id }
      }
    ],
    awaitRefetchQueries: true
  })

  const [moveContactFn] = useMoveContactMutation()

  const triggerGtmEvent = (eventName: string): void => {
    window.dataLayer.push({
      event: eventName
    })
  }

  // TODO: update status to be an object that contains the popover and gtm info so we can map instead of hardcoding cases here
  const handleCategoryChange = useCallback(
    (status: ContactStatus): void => {
      const categoryUpdate = status
      let onCompletedCallback: () => void = () => {}
      switch (categoryUpdate) {
        case ContactStatus.Disqualified:
          togglePopover('loading', `Moving "${contactName}" to Disqualified`)
          onCompletedCallback = () => {
            triggerGtmEvent('ga-disqualify-contact')
            togglePopover('success', 'Disqualified...')
          }
          break
        case ContactStatus.Active:
          togglePopover('loading', `Moving "${contactName}" to Active`)
          onCompletedCallback = () => {
            triggerGtmEvent('ga-activate-contact')
            togglePopover('success', 'Activated...')
          }
          break
        case ContactStatus.Archived:
          togglePopover('loading', `Archiving "${contactName}"`)
          onCompletedCallback = () => {
            triggerGtmEvent('ga-archive-contact')
            togglePopover('success', 'Archived...')
          }
          break
      }
      handleUpdateContact(
        updateContactFn,
        { status: categoryUpdate },
        contact.id,
        id,
        onCompletedCallback
      )
        .then(() => {
          toggleContactFlyout()
        })
        .catch((err) => {
          togglePopover('error', error?.message ?? 'Error...')
          console.log(err)
        })
    },
    [
      updateContactFn,
      contact.id,
      id,
      togglePopover,
      contactName,
      error?.message
    ]
  )

  const afterButton = useMemo(() => {
    if (
      !isDBAActionDisabled &&
      !contactHasBullhornId &&
      !contactHasTitleLongerThan50
    )
      return null

    let message

    switch (true) {
      case contactHasTitleLongerThan50:
        message =
          'The title exceeds 50 characters. Please shorten it to enable the button for DBA creation'
        break
      case isDBAActionDisabled && !companyHasBullhornId:
        message = 'Company needs to exist to DBA a contact'
        break
      case isDBAActionDisabled && companyHasBullhornId:
        message =
          'Email, Title, and Direct Phone Number are required to DBA a contact'
        break
      case !isDBAActionDisabled:
        message = 'DBA already exists for this contact'
        break
      default:
        message = '' // Maneja un caso por defecto si es necesario
        break
    }

    return <FlyoutHelpText message={message} className={styles['list-extra']} />
  }, [
    companyHasBullhornId,
    contactHasBullhornId,
    isDBAActionDisabled,
    contactHasTitleLongerThan50
  ])

  const openDBAModal = useCallback(() => {
    setIsSelectDBAModalOpen(true)
  }, [])
  const closeDBAModal = useCallback(() => {
    setIsSelectDBAModalOpen(false)
  }, [])
  const openMoveContactModal = useCallback(() => {
    setIsMoveContactModalOpen(true)
  }, [])

  return (
    <>
      <div className={styles.container}>
        <Button
          as='button'
          type='button'
          style='icon-only'
          ariaLabel='contact menu'
          onClick={toggleContactFlyout}
        >
          <Icon symbol='MoreVertOutlinedIcon' color='icon-default' size='lg' />
        </Button>
        <div
          className={classNames(
            styles['default-wrapper'],
            styles[`sticky-flyout-pos-${position}`]
          )}
        >
          <Flyout
            type='default'
            isOpen={contactFlyoutOpen}
            setIsOpen={setContactFlyoutOpen}
            width='lg'
          >
            <ul>
              <FlyoutListItem
                key='item-1'
                onClick={openEditContactForm}
                buttonText='Edit Contact'
                iconSymbol='EditOutlinedIcon'
              />
              {ListType.Leads === listType && (
                <>
                  <FlyoutListItem
                    key='item-2'
                    iconSymbol='PostAddOutlinedIcon'
                    onClick={openDBAModal}
                    buttonText='DBA'
                    isDisabled={
                      isDBAActionDisabled || contactHasTitleLongerThan50
                    }
                    buttonProps={{ ariaLabel: 'DBA' }}
                    afterButton={afterButton}
                  />
                  <FlyoutListItem
                    key='item-4'
                    onClick={openMoveContactModal}
                    isDisabled={!companyHasBullhornId}
                    iconSymbol='PersonAddAlt1OutlinedIcon'
                    buttonText='Move to Active Call List'
                    afterButton={
                      !companyHasBullhornId && (
                        <FlyoutHelpText
                          message='Company needs to exist to move to active call list'
                          className={styles['list-extra']}
                        />
                      )
                    }
                  />
                  <FlyoutListItem
                    key='item-5'
                    onClick={() => {
                      setArchiveDialogOpen(true)
                    }}
                    iconSymbol='DeleteOutlinedIcon'
                    buttonText='Remove Contact'
                  />
                </>
              )}
              {ListType.Active === listType && (
                <>
                  <FlyoutListItem
                    key='item-2'
                    iconSymbol='PostAddOutlinedIcon'
                    onClick={openDBAModal}
                    buttonText='DBA'
                    isDisabled={
                      isDBAActionDisabled || contactHasTitleLongerThan50
                    }
                    buttonProps={{ ariaLabel: 'DBA' }}
                    afterButton={afterButton}
                  />
                  {contact.referral ? (
                    <FlyoutListItem
                      key='item-4'
                      onClick={() => {
                        void referContactFn({
                          variables: {
                            id: contact.id,
                            flag: !contact.referral
                          }
                        })
                      }}
                      iconSymbol='SpeakerNotesOffOutlinedIcon'
                      buttonText='Unmark Referral'
                    />
                  ) : (
                    <FlyoutListItem
                      key='item-4'
                      onClick={() => {
                        void referContactFn({
                          variables: {
                            id: contact.id,
                            flag: !contact.referral
                          }
                        })
                      }}
                      iconSymbol='AddCommentOutlinedIcon'
                      buttonText='Mark as Referral'
                    />
                  )}
                  {contactCategory === AccordionTypes.ActiveContacts ? (
                    <FlyoutListItem
                      key='item-5'
                      onClick={() => {
                        handleCategoryChange(ContactStatus.Disqualified)
                      }}
                      iconSymbol='BlockIcon'
                      buttonText='Disqualify Contact'
                    />
                  ) : (
                    <FlyoutListItem
                      key='item-5'
                      onClick={() => {
                        handleCategoryChange(ContactStatus.Active)
                      }}
                      iconSymbol='PersonAddAlt1OutlinedIcon'
                      buttonText='Move to Active'
                    />
                  )}
                  <FlyoutListItem
                    key='item-6'
                    onClick={() => {
                      setArchiveDialogOpen(true)
                    }}
                    iconSymbol='DeleteOutlinedIcon'
                    buttonText='Remove Contact'
                  />
                </>
              )}
            </ul>
          </Flyout>
        </div>
      </div>
      <Modal
        key={`dba-contact-${contactName}`}
        open={isSelectDBAModalOpen}
        onRequestClose={closeDBAModal}
        closeOnOutsideClick={false}
        title={`DBA - ${contactName}`}
      >
        <SelectDBAForm
          isModalOpen={isSelectDBAModalOpen}
          contact={contact}
          onRequestClose={closeDBAModal}
          togglePopover={togglePopover}
          listType={listType}
          companyObject={companyObject}
        />
      </Modal>
      <ArchiveModal
        isOpen={archiveDialogOpen}
        setIsOpen={setArchiveDialogOpen}
        onConfirm={() => {
          handleCategoryChange(ContactStatus.Archived)
        }}
        entityName={contactName}
        type='contact'
      />
      <MoveContactModal
        isOpen={isMoveContactModalOpen}
        setIsOpen={setIsMoveContactModalOpen}
        onConfirm={() => {
          handleMoveContactToActive(
            moveContactFn,
            ListType.Active,
            contact.id,
            companyObject,
            id,
            togglePopover,
            toggleContactFlyout
          )
        }}
        contactName={contactName}
      />
    </>
  )
}

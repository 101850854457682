import {
  useRef,
  useState,
  useEffect,
  type ReactNode,
  type MouseEvent,
  type ReactElement,
  type FC,
  type ChangeEvent
} from 'react'
import classNames from 'classnames'
import { TextInput as UIKTextInput } from '@alku/ui-kit'

import * as styles from './form-elements.module.css'
import * as sharedStyles from '../../../styles.module.css'
import { Button } from '../button/Button'
import { Icon } from '../icons/Icons'
import { ValidationError } from '../validationError/ValidationError'
import { ErrorMessage, useField } from 'formik'
import Select, { type DropdownIndicatorProps, components } from 'react-select'
import { type SelectProps, type SelectValue } from '../select/types'

interface LabelProps {
  children: ReactNode
  inputId: string
  inputIsRequired?: boolean
  screenReaderOnly?: boolean
  hasError?: boolean
}

export const Label = ({
  children,
  inputId,
  inputIsRequired = false,
  screenReaderOnly = false,
  hasError = false
}: LabelProps): JSX.Element => {
  return (
    <label
      htmlFor={inputId}
      className={classNames(
        styles.label,
        hasError && styles['error-text'],
        inputIsRequired ? styles.required : '',
        screenReaderOnly && sharedStyles['u-sr-only']
      )}
    >
      {children}
    </label>
  )
}

interface TextInputProps
  extends React.InputHTMLAttributes<HTMLInputElement | HTMLTextAreaElement> {
  id: string
  required?: boolean
  type?: string
  readOnly?: boolean
  name?: string
  placeholder?: string
  error?: string
  hasError?: boolean
  handleChange?: (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void
  touched?: boolean
  label?: string
  isShown?: boolean
  multiline?: boolean
  rows?: number
}

export const TextInput: FC<TextInputProps> = (props): ReactElement => {
  const {
    id,
    required = false,
    type = 'text',
    name = '',
    readOnly = false,
    value = '',
    error,
    hasError,
    handleChange = () => {},
    label = '',
    touched = false,
    isShown = true,
    multiline = false,
    ...inputProps // any addional native input props
  } = props
  let pattern = {}
  // TODO: we can probably get pretty var with just patterns and native browser validation..
  switch (type) {
    case 'url':
      pattern = { pattern: 'https://.*' }
      break
    case 'postal':
      pattern = { pattern: '[0-9]{5}' }
      break
    case 'linkedin':
      pattern = { pattern: 'https://www.linkedin.com/.*' }
      break
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention, @typescript-eslint/no-unused-vars
  const [field, _, helpers] = useField(name)
  const { setValue } = helpers

  useEffect(() => {
    void setValue(value ?? '')
  }, [value, setValue])

  return (
    <>
      <div
        className={
          !isShown
            ? styles.hidden
            : name.includes('phone')
            ? styles.phoneInputWidth
            : ''
        }
      >
        <UIKTextInput
          type={type}
          id={id}
          label={label}
          name={field.name}
          errorMessage={touched && error ? error : undefined}
          disabled={readOnly}
          value={value as string} // controlled input; AMCL uses Formik
          autoComplete='off'
          onChange={handleChange}
          required={required}
          style={multiline ? {} : { height: '4.5rem' }}
          multiline={multiline}
          {...pattern}
          {...inputProps}
        />
      </div>
      {/* Keep the legacy version for backwards compat. Error field is for UIKit. */}
      {!error && hasError && (
        <ErrorMessage
          name={field.name}
          component={ValidationError}
          className='error'
        />
      )}
    </>
  )
}

// suggestion from scott https://www.patterns.dev/posts/compound-pattern
// TODO: either move select in here or move this into select. want to be consistent about how form inputs are organized
export const PhoneDropdown = ({
  options,
  isSearchable = false,
  placeholder,
  required = false,
  id,
  name = '',
  readOnly = false,
  value,
  hasError = false,
  label = '',
  touched = false,
  onChange
}: SelectProps): JSX.Element => {
  const [selectedOption, setSelectedOption] = useState<SelectValue | null>(
    value ?? null
  )

  // eslint-disable-next-line @typescript-eslint/naming-convention, @typescript-eslint/no-unused-vars
  const [field, _, helpers] = useField(name)
  const { setValue } = helpers

  useEffect(() => {
    if (value != null) void setValue(value.value)
  }, [value, setValue])

  const handlePhoneChange = (selected: SelectValue | null): void => {
    setSelectedOption(selected)
    if (selected != null) void setValue(selected.value)
    if (selected != null && onChange) onChange(selected.value)
  }

  const SelectArrowIcon = (): JSX.Element => {
    return <Icon symbol='ArrowDropDownOutlinedIcon' color='grey-8' size='xså' />
  }

  const DropdownIndicator = (
    props: DropdownIndicatorProps<any>
  ): JSX.Element => {
    return (
      <components.DropdownIndicator {...props}>
        <SelectArrowIcon />
      </components.DropdownIndicator>
    )
  }

  return (
    <Select
      components={{ DropdownIndicator }}
      className={classNames(
        styles['phone-type-select']
        // hasError && touched ? styles.error : ''
      )}
      defaultValue={selectedOption}
      onChange={handlePhoneChange}
      options={options}
      unstyled
      isSearchable={isSearchable}
      placeholder={placeholder}
      classNamePrefix='react-select'
      isClearable={isSearchable}
      id={id}
      name={field.name}
      isDisabled={readOnly}
      isMulti={false}
      required={required}
      // menuIsOpen // helpful for testing styles
    />
  )
}

interface InfoNoteProps {
  children: ReactNode
}

export const InfoNote = ({ children }: InfoNoteProps): JSX.Element => {
  return <p className={styles.note}>{children}</p>
}

export enum FileInputType {
  CSV = 'text/csv',
  JPG = 'image/jpeg',
  PNG = 'image/png',
  PDF = 'application/pdf',
  XLS = 'application/vnd.ms-excel',
  Any = '*'
}

function getExtension(value: string): string | undefined {
  const keys = Object.keys(FileInputType) as Array<keyof typeof FileInputType>
  for (const key of keys) if (FileInputType[key] === value) return '.' + key
  return undefined
}

interface FileInputProps {
  id: string
  required?: boolean
  readOnly?: boolean
  label?: string
  accept?: FileInputType
  setFileUploaded: (file: File) => void
  fileUploaded?: File
  onChange: (e: React.ChangeEvent<HTMLElement>) => void
}
export const FileInput = ({
  id,
  required = false,
  readOnly = false,
  label = '',
  accept = FileInputType.Any,
  setFileUploaded,
  fileUploaded,
  onChange
}: FileInputProps): JSX.Element => {
  const acceptString = getExtension(accept)
  const [isUploaded, setIsUploaded] = useState<boolean>(false)
  const hiddenFileInput = useRef<HTMLInputElement | null>(null)

  const handleClick = (e: React.MouseEvent<HTMLElement>): void => {
    e.preventDefault()
    if (hiddenFileInput.current != null) hiddenFileInput.current.click()
  }

  const handleChange = (e: React.ChangeEvent<HTMLElement>): void => {
    e.preventDefault()
    if (hiddenFileInput?.current?.files?.[0] === undefined) return
    setFileUploaded(hiddenFileInput?.current?.files?.[0])
    setIsUploaded(true)
    onChange(e)
  }

  return (
    <div className={styles.file}>
      <Label inputId='upload-button' inputIsRequired={required}>
        {label}
      </Label>
      <div className={styles.fileInput}>
        <Button
          as='button'
          id='browse-file-button'
          style='secondary'
          onClick={(e) => {
            handleClick(e as MouseEvent<HTMLButtonElement>)
          }}
        >
          Browse...
        </Button>
        {isUploaded && (
          <>
            <Icon symbol='AttachFileOutlinedIcon' color='alku-red' size='md' />
            {fileUploaded?.name}
          </>
        )}
      </div>
      <input
        type='file'
        id={id}
        accept={accept}
        className={styles.fileHiddenInput}
        required={required}
        disabled={readOnly}
        ref={hiddenFileInput}
        onChange={handleChange}
      />
      <InfoNote>
        Supported file type: <b> {acceptString}</b>
      </InfoNote>
    </div>
  )
}
